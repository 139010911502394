<template>
  <!--
      The novalidate attribute is required on any form that has: a flatpickr datetime input with the `static` prop set, and the form
      itself might be within a modal. This happens because flatpickr sets a hidden attribute with validation, that the browser is unable to
      focus on. When this happens, your browser console will have the error `An invalid form control with name='' is not focusable.` and
      the user will be unable to submit the form.
  -->
  <b-form
    novalidate
    @submit.stop.prevent="handleSubmit"
  >
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.date_bucket"
          label-for="work-order-date_bucket"
        >
          <sfs-date-range-picker
            id="work-order-date_bucket"
            v-model="$v.form.date_bucket.$model"
            :with-class="{
              'is-invalid':
                ($v.form.date_bucket_start.$dirty && $v.form.date_bucket_start.$error) ||
                ($v.form.date_bucket_end.$dirty && $v.form.date_bucket_end.$error),
            }"
            :class="{
              'is-invalid':
                ($v.form.date_bucket_start.$dirty && $v.form.date_bucket_start.$error) ||
                ($v.form.date_bucket_end.$dirty && $v.form.date_bucket_end.$error),
            }"
            placeholder="Select Dates"
            @on-change="onDateBucketChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.date_bucket_start.$dirty"
            id="work-order-date_bucket_start-feedback"
          >
            <span v-if="!$v.form.date_bucket_start.required">Please enter a start date.</span>
            <span v-if="!$v.form.date_bucket_start.serverFailed">{{ serverErrors.date_bucket_start }}</span>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="$v.form.date_bucket_end.$dirty"
            id="work-order-date_bucket_end-feedback"
          >
            <span v-if="!$v.form.date_bucket_end.required">Please enter a end date.</span>
            <span v-if="!$v.form.date_bucket_end.serverFailed">{{ serverErrors.date_bucket_end }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.number"
          label-for="work-order-number"
        >
          <b-form-input
            id="work-order-number"
            v-model="$v.form.number.$model"
            :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.number.$dirty"
            id="work-order-number-feedback"
          >
            <span v-if="!$v.form.number.maxLength">
              The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="work-order-client_id"
        >
          <client-select
            id="work-order-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            id="work-order-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a client.</span>
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.location_id"
          label-for="work-order-location_id"
        >
          <location-select
            id="work-order-location_id"
            :client-id="form.client_id"
            :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
            :value="form.location"
            @input="onLocationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.location_id.$dirty"
            id="work-order-location_id-feedback"
          >
            <span v-if="!$v.form.location_id.required">Please enter a location.</span>
            <span v-if="!$v.form.location_id.serverFailed">{{ serverErrors.location }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.customer_identifier"
          label-for="work-order-customer_identifier"
        >
          <b-form-input
            id="work-order-customer_identifier"
            v-model="$v.form.customer_identifier.$model"
            :state="$v.form.customer_identifier.$dirty ? !$v.form.customer_identifier.$error : null"
            placeholder="Optional"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.customer_identifier.$dirty"
            id="work-order-customer_identifier-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.customer_identifier.maxLength"> The customer identifier must be less than {{ $v.form.customer_identifier.$params.maxLength.max + 1 }} characters.</span>
            <span v-if="!$v.form.customer_identifier.serverFailed">
              {{ serverErrors.customer_identifier }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col lg="6">
            <b-form-group
              :label="fieldLabels.cost_limit"
              label-for="work-order-cost_limit"
              class="mb-0"
            >
              <b-input-group
                prepend="$"
                :class="{ 'is-invalid': $v.form.cost_limit.$dirty && $v.form.cost_limit.$error }"
              >
                <b-form-input
                  id="work-order-cost_limit"
                  v-model="$v.form.cost_limit.$model"
                  placeholder="Optional"
                  :state="$v.form.cost_limit.$dirty ? !$v.form.cost_limit.$error : null"
                  type="text"
                />
              </b-input-group>
              <b-form-invalid-feedback
                v-if="$v.form.cost_limit.$dirty"
                id="work-order-cost_limit-feedback"
              >
                <span v-if="!$v.form.cost_limit.decimal">Please enter a number.</span>
                <span v-if="!$v.form.cost_limit.serverFailed">
                  {{ serverErrors.cost_limit_cents }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group
              :label="fieldLabels.price_limit"
              label-for="work-order-price_limit"
              class="mb-0"
            >
              <b-input-group
                prepend="$"
                :class="{ 'is-invalid': $v.form.price_limit.$dirty && $v.form.price_limit.$error }"
              >
                <b-form-input
                  id="work-order-price_limit"
                  v-model="$v.form.price_limit.$model"
                  placeholder="Optional"
                  :state="$v.form.price_limit.$dirty ? !$v.form.price_limit.$error : null"
                  type="text"
                />
              </b-input-group>
              <b-form-invalid-feedback
                v-if="$v.form.price_limit.$dirty"
                id="work-order-price_limit-feedback"
              >
                <span v-if="!$v.form.price_limit.decimal">Please enter a number.</span>
                <span v-if="!$v.form.price_limit.serverFailed">
                  {{ serverErrors.price_limit_cents }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <small class="form-text">
              These limits are the maximum allowed to be billed and paid
            </small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.service_id"
          label-for="work-order-service_id"
        >
          <service-select
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error, 'mb-1': true }"
            id="work-order-service_id"
            :placeholder="form.client?.work_order_charges_require_service ? '' : 'Optional'"
            :client-id="form.client_id"
            client-unassigned
            :value="form.service"
            @input="onServiceChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            id="work-order-service_id-feedback"
          >
            <span v-if="!$v.form.service_id.required">Please enter a service.</span>
            <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service }}</span>
          </b-form-invalid-feedback>
          <b-form-textarea
            id="work-order-description"
            v-model="$v.form.description.$model"
            placeholder="Enter a description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            rows="4"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            id="work-order-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="fieldLabels.priority"
              label-for="work-order-priority"
            >
              <b-form-input
                id="work-order-priority"
                v-model="$v.form.priority.$model"
                placeholder="Optional"
                :state="$v.form.priority.$dirty ? !$v.form.priority.$error : null"
                type="text"
              />
              <b-form-invalid-feedback
                v-if="$v.form.priority.$dirty"
                id="work-order-priority-feedback"
              >
                <span v-if="!$v.form.priority.maxLength">
                  The priority must be less than {{ $v.form.priority.$params.maxLength.max + 1 }} characters.
                </span>
                <span v-if="!$v.form.priority.serverFailed">{{ serverErrors.priority }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="fieldLabels.maintainance_type"
              label-for="work-order-maintainance_type"
            >
              <b-form-input
                id="work-order-maintainance_type"
                v-model="$v.form.maintainance_type.$model"
                :state="$v.form.maintainance_type.$dirty ? !$v.form.maintainance_type.$error : null"
                placeholder="Optional"
                type="text"
              />
              <b-form-invalid-feedback
                v-if="$v.form.maintainance_type.$dirty"
                id="work-order-maintainance_type-feedback"
              >
                <!-- prettier-ignore -->
                <span v-if="!$v.form.maintainance_type.maxLength">
                  The maintainance type must be less than {{ $v.form.maintainance_type.$params.maxLength.max + 1 }} characters.
                </span>
                <span v-if="!$v.form.maintainance_type.serverFailed">
                  {{ serverErrors.maintainance_type }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label-for="work-order-parts_needed">
          <b-form-checkbox
            switch
            id="work-order-parts_needed"
            v-model="$v.form.parts_needed.$model"
          >
            {{ fieldLabels.parts_needed }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr v-if="isCompleted || !isAssignedOrUnassignedOrPending">

    <b-row v-if="isCompleted">
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.checked_in_at"
          label-for="work-order-checked_in_at"
        >
          <b-input-group>
            <sfs-date-time-picker
              id="work-order-checked_in_at"
              v-model="form.checked_in_at"
              :with-class="{ 'is-invalid': $v.form.checked_in_at.$dirty && $v.form.checked_in_at.$error }"
              :time-zone="form.location && form.location.time_zone"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.checked_in_at.$dirty"
            id="work-order-checked_in_at-feedback"
          >
            <span v-if="!$v.form.checked_in_at.serverFailed">{{ serverErrors.checked_in_at }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.checked_out_at"
          label-for="work-order-checked_out_at"
        >
          <b-input-group>
            <sfs-date-time-picker
              id="work-order-checked_out_at"
              v-model="form.checked_out_at"
              :with-class="{ 'is-invalid': $v.form.checked_out_at.$dirty && $v.form.checked_out_at.$error }"
              :time-zone="form.location && form.location.time_zone"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.checked_out_at.$dirty"
            id="work-order-checked_out_at-feedback"
          >
            <span v-if="!$v.form.checked_out_at.serverFailed">{{ serverErrors.checked_out_at }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="!isAssignedOrUnassignedOrPending">
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.assigned_to_id"
          label-for="work-order-assigned_to_id"
        >
          <user-select
            id="work-order-assigned_to_id"
            :select-class="{ 'is-invalid': $v.form.assigned_to_id.$dirty && $v.form.assigned_to_id.$error }"
            select-label="name"
            :value="form.assigned_to"
            :filter-params="technicianSelectParams"
            @input="onAssignedToChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.assigned_to_id.$dirty"
            id="work-order-assigned_to_id-feedback"
          >
            <span v-if="!$v.form.assigned_to_id.required">Please enter an assigned technician.</span>
            <span v-if="!$v.form.assigned_to_id.serverFailed">{{ serverErrors.assigned_to_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.done_by_id"
          label-for="work-order-done_by_id"
        >
          <user-select
            id="work-order-done_by_id"
            :select-class="{ 'is-invalid': $v.form.done_by_id.$dirty && $v.form.done_by_id.$error }"
            select-label="name"
            :disabled="!form.assigned_to_id"
            :manager-id="form.assigned_to_id"
            :primary-option="form.assigned_to"
            :value="form.done_by"
            @input="onDoneByChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.done_by_id.$dirty"
            id="work-order-done_by_id-feedback"
          >
            <span v-if="!$v.form.done_by_id.required">Please enter the technician the work is completed by.</span>
            <span v-if="!$v.form.done_by_id.serverFailed">{{ serverErrors.done_by_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="work-order-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="work-order-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Update
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import { required, maxLength, requiredIf, decimal } from 'vuelidate/lib/validators';
import LocationSelect from '@/components/admin/locations/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import { WORK_ORDER_FORM_LABELS, WORK_ORDER_STATUS_PENDING, WORK_ORDER_STATUS_ASSIGNED, WORK_ORDER_STATUS_UNASSIGNED, WORK_ORDER_STATUS_COMPLETED } from '@/constants/workOrders.js';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'WorkOrderForm',
  components: {
    LocationSelect,
    ClientSelect,
    ServiceSelect,
    UserSelect,
  },
  mixins: [formMixin],
  props: {
    client_id: Number,
    location_id: Number,
    service_id: Number,
    number: String,
    customer_identifier: String,
    status: String,
    description: String,
    priority: String,
    maintainance_type: String,
    date_bucket_start: String,
    date_bucket_end: String,
    checked_in_at: String,
    checked_out_at: String,
    assigned_to_id: Number,
    done_by_id: Number,
    client: Object,
    location: Object,
    service: Object,
    assigned_to: Object,
    done_by: Object,
    cost_limit: Object,
    price_limit: Object,
    parts_needed: Boolean,
  },
  data() {
    return {
      form: {
        client_id: this.client_id,
        location_id: this.location_id,
        service_id: this.service_id,
        number: this.number,
        customer_identifier: this.customer_identifier,
        description: this.description,
        priority: this.priority,
        maintainance_type: this.maintainance_type,
        date_bucket_start: this.date_bucket_start,
        date_bucket_end: this.date_bucket_end,
        checked_in_at: this.checked_in_at,
        checked_out_at: this.checked_out_at,
        date_bucket: [
          this.date_bucket_start && this.$options.filters.dateFormat(this.date_bucket_start),
          this.date_bucket_end && this.$options.filters.dateFormat(this.date_bucket_end),
        ],
        cost_limit: this.cost_limit && (this.cost_limit.amount || this.cost_limit.amount === 0) ? this.cost_limit.amount / 100 : null,
        cost_limit_cents: this.cost_limit && (this.cost_limit.amount || this.cost_limit.amount === 0) ? this.cost_limit.amount : null,
        price_limit: this.price_limit && (this.price_limit.amount || this.price_limit.amount === 0) ? this.price_limit.amount / 100 : null,
        price_limit_cents: this.price_limit && (this.price_limit.amount || this.price_limit.amount === 0) ? this.price_limit.amount : null,
        assigned_to_id: this.assigned_to_id,
        done_by_id: this.done_by_id,
        client: this.client,
        location: this.location,
        service: this.service,
        assigned_to: this.assigned_to,
        done_by: this.done_by,
        parts_needed: this.parts_needed,
      },
      fieldLabels: {
        ...WORK_ORDER_FORM_LABELS,
        cost_limit: `${this.$store.getters.translate('work_orders.cost', 'Cost')} Limit`,
        price_limit: `${this.$store.getters.translate('work_orders.price', 'Price')} Limit`,
        cost_limit_cents: 'Limit',
        price_limit_cents: 'Limit',
      },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
      service_id: {
        required: requiredIf(function () {
          return this.form.client?.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('service');
        },
      },
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      priority: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('priority');
        },
      },
      maintainance_type: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('maintainance_type');
        },
      },
      date_bucket_start: {
        required: requiredIf(function () {
          return this.status !== WORK_ORDER_STATUS_PENDING;
        }),
        serverFailed() {
          return !this.hasServerErrors('date_bucket_start');
        },
      },
      date_bucket_end: {
        required: requiredIf(function () {
          return this.status !== WORK_ORDER_STATUS_PENDING;
        }),
        serverFailed() {
          return !this.hasServerErrors('date_bucket_end');
        },
      },
      checked_in_at: {
        serverFailed() {
          return !this.hasServerErrors('checked_in_at');
        },
      },
      checked_out_at: {
        serverFailed() {
          return !this.hasServerErrors('checked_out_at');
        },
      },
      cost_limit: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_limit_cents');
        },
      },
      price_limit: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_limit_cents');
        },
      },
      date_bucket: {},
      assigned_to_id: {
        required: requiredIf(function () {
          return this.form.service?.work_order_charges_require_user;
        }),
        serverFailed() {
          return !this.hasServerErrors('assigned_to_id');
        },
      },
      done_by_id: {
        required: requiredIf(function () {
          return this.form.service?.work_order_charges_require_user;
        }),
        serverFailed() {
          return !this.hasServerErrors('done_by_id');
        },
      },
      parts_needed: {},
    },
  },
  computed: {
    isCompleted() {
      return this.status === WORK_ORDER_STATUS_COMPLETED;
    },
    isAssignedOrUnassignedOrPending() {
      return this.status === WORK_ORDER_STATUS_ASSIGNED || this.status === WORK_ORDER_STATUS_UNASSIGNED || this.status === WORK_ORDER_STATUS_PENDING;
    },
  },
  watch: {
    'form.cost_limit': function (newCost) {
      if (newCost) {
        this.form.cost_limit_cents = isNaN(newCost) ? null : Math.round(newCost * 100);
      } else {
        this.form.cost_limit_cents = null;
      }
    },
    'form.price_limit': function (newPrice) {
      if (newPrice) {
        this.form.price_limit_cents = isNaN(newPrice) ? null : Math.round(newPrice * 100);
      } else {
        this.form.price_limit_cents = null;
      }
    },
  },
  methods: {
    onDateBucketChange(dates) {
      this.form.date_bucket_start = dates[0] || '';
      this.form.date_bucket_end = dates[1] || '';
    },
    onLocationChange(location) {
      this.form.location_id = location ? location.id : null;
      this.form.location = location;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
      this.form.location_id = null;
      this.form.location = null;
    },
    onServiceChange(service) {
      this.form.service_id = service ? service.id : null;
      this.form.service = service;
    },
    onAssignedToChange(user) {
      this.form.assigned_to_id = user ? user.id : null;
      this.form.assigned_to = user;
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
    onDoneByChange(user) {
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
  },
};
</script>
